import { post } from '@/utils/request'

// 家庭审核列表
export function applicationList(data) {
    return post('/admin/family/applicationList', data)
}

// 审核详情
export function applicationInfo(data) {
    return post('/admin/family/applicationInfo', data)
}
// 拒绝
export function applicationCancel(data) {
    return post('/admin/family/applicationCancel', data)
}
// 同意
export function applicationPass(data) {
    return post('/admin/family/applicationPass', data)
}
//家庭列表

export function businessList(data) {
    return post('/admin/family/businessList', data)
}
//家庭详情
export function businessInfo(data) {
    return post('/admin/family/businessInfo', data)
}